import React, {Component} from 'react';
import PropTypes from 'prop-types';

const Register = (props) => {
  return (
    <div className="register">
      <form name="register" method="POST" data-netlify="true" action="/registerSuccess">
        <label htmlFor="name">Name</label>
          <input type="text" name="name" />
        <label htmlFor="company">Additional Guests</label>
          <input type="text" name="addGuests" />
        <label htmlFor="company">Company</label>
          <input type="text" name="company" />
        <label htmlFor="email">Email</label>
          <input type="text" name="email" />
        <button className="register--submit" type="submit">Send</button>
      </form>  
    </div>
  );
}

export default Register;

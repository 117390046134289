import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet';
import RegisterSuccessFBPixel from '../components/RegisterSuccessFBPixel.js';
import GenericFBPixel from  '../components/GenericFBPixel.js';

const HeaderEvent = (props) => {
  let pixel = {};
  if (props.isLead) {
    pixel = RegisterSuccessFBPixel;   
  } else {
    pixel = GenericFBPixel;
  }
  return(
    <header id="headerEvent" className="alt"> 
        <Helmet>
          <script>
            {pixel}
          </script> 
        </Helmet>
        {props.sponsor &&
          <img src={props.sponsor.leftImage} />
        }
        <img src={props.mainImage} />
        {props.sponsor &&
          <img src={props.sponsor.leftImage} />
        }
    </header>
  );
}

export default HeaderEvent;

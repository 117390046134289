import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet';
import Footer from '../components/FooterEvent'

import HeaderEvent from '../components/HeaderEvent';
import fusion from '../assets/images/carriers/logo_fusion.gif';
import aerohive from '../assets/images/carriers/aerohive.png';
import Register from '../components/Register.jsx';

class RegisterPage extends React.Component {
  render() {
    const eventSponsors = [fusion, aerohive];
    return (
      <span>
      <div>
        <Helmet title="Register" />
        <HeaderEvent sponsors={eventSponsors}/>
        <div id="main" className="register__main">
          <section id="content" className="main">

          <h1 className="registerPage__header" >Event Registration</h1>
            <div id="registerPage">
              <div className="registerPage__info"> 
                <div className="registerPage__info_line">
                  <div className="registerPage__info_title">Panel Discussion: </div>
                  <div><h3>Complete Cloud Solutions</h3>
                  Learn how to provide a true Single Source Network Solution AND how to take control in a world of Wi-Fi Complexity
                  </div>

                </div>
                  <div className="registerPage__info_line"> 
                  <div className="registerPage__info_title">Time:</div>
                <div>Oct 4, 2018 3:00 - 5:00 PM in <strong> EST </strong></div>
                </div>
              </div>
            <Register />
            </div>
          </section>
        </div>
        
      </div>
      <Footer />
      </span>
    )
  }
}

export default RegisterPage

export const pageQuery = graphql`
  query RegisterQuery{
    site {
      siteMetadata {
        title
      }
    }
  }
`

import React from 'react'
import Link from 'gatsby-link'

import logo from '../assets/icons/logo white.png';

const Footer = (props) => (
    <footer id="footer" className="event__footer">
        <section>
            <h2><img src={logo} alt="Business Network Team" /></h2>
        </section>
        <section>
        </section>
        <p className="copyright"> Copyright&copy;  2018 | Business Network Team, Inc. | (440) 625-1955 </p>
    </footer>
)

export default Footer
